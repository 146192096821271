<template>
  <div class="brief">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <div class="pc_one_box" data-scroll-section>
        <img src="~img/cultureimg/10.png" class="bg_img" data-0="top:0%;" data-1080="top:100%;" />
        <img src="~img/cultureimg/22.png" class="icon_img" data-0="top:15%;opacity:1" data-1080="top:100%;opacity:0" />
        <h3 data-0="top:41%;opacity:1" data-1080="top:100%%;opacity:0">
          安谋科技（中国）有限公司
        </h3>
        <div class="pc_fenge" data-0="top:50%;opacity:1" data-1080="top:110%%;opacity:0"></div>
        <p v-html="text1.text" data-0="top:70%;opacity:1" data-1080="top:100%; opacity:0" />
      </div>
      <div class="pc_two_box" data-scroll-section>
        <div class="content">
          <div class="tow-box" data-400="opacity:0;" data-540="opacity:1;top:2.15rem;" data-1080="top:.5rem;">
            <ul ref="towBox">
              <li>
                <div class="text_box clearfix">
                  <p class="p1">{{ num }}</p>
                  <p class="p2">+</p>
                  <p class="p3">家</p>
                </div>
                <h5>中国客户数量</h5>
              </li>
              <li>
                <div class="text_box clearfix">
                  <p class="p1">{{ num2 }}%</p>
                  <p class="p2">+</p>
                </div>
                <h5>国产SoC基于</h5>
                <h5>Arm处理器技术</h5>
              </li>
              <li>
                <div class="text_box clearfix">
                  <p class="p1">{{ num3 }}</p>
                  <p class="p2">+</p>
                  <p class="p3">亿</p>
                </div>
                <h5>基于Arm架构</h5>
                <h5>"中国芯"累计出货量</h5>
              </li>
              <li>
                <div class="text_box clearfix">
                  <p class="p1">{{ num6 }}</p>
                  <p class="p2">%</p>
                  <p class="p3">项</p>
                </div>
                <h5>自研产品线均已实现高质量交付</h5>
                <h5>且助力客户成功流片</h5>
                <!-- <h5>出货量成长倍数</h5> -->
              </li>
            </ul>
          </div>
          <div class="new_box" data-1080="bottom:-0.61rem;" data-2620="bottom:2rem;">
            <div class="address">
              <img src="~img/cultureimg/14.png" alt="" />
              <h4>办公地点</h4>
              <p>深圳、上海、北京、成都</p>
              <img src="~img/cultureimg/17.png" class="text_img" />
            </div>
            <div class="bantouming"></div>
            <div class="pc_label" ref="people">
              <div class="left">
                <img src="~img/cultureimg/12.png" alt="" />
                <p>在职员工</p>
                <span class="span1">{{ num4 }}</span>
                <span class="span2">+</span>
              </div>
              <div class="right">
                <img src="~img/cultureimg/13.png" alt="" />
                <p>自研业务核心技术专利数量</p>
                <span class="span1">{{ num5 }}</span>
                <span class="span2">+</span>
              </div>
            </div>
          </div>
          <!-- <div class="address" data-1080="bottom:0.24rem;" data-2620="bottom:5rem;">
            <img src="~img/cultureimg/14.png" alt="" />
            <h4>办公地点</h4>
            <p>深圳、上海、北京、成都</p>
          </div>
          <div class="bantouming" data-937="bottom:-0.61rem;" data-2007="bottom:2rem;"></div>
          <div class="pc_label" ref="people" data-1080="bottom:-0.61rem;" data-2620="bottom:2rem;">
            <div class="left">
              <img src="~img/cultureimg/12.png" alt="" />
              <p>在职员工</p>
              <span class="span1">{{ num4 }}</span>
              <span class="span2">+</span>
            </div>
            <div class="right">
              <img src="~img/cultureimg/13.png" alt="" />
              <p>自研IP</p>
              <span class="span1">{{ num5 }}%</span>
              <span class="span2">+</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="pc_three_box" data-scroll-section>
        <div class="jiange"></div>
        <div class="describe" ref="describe" data-1400="top:0rem;" data-2000="top:-2rem;" data-3000="top:-4rem;">
          <!-- <img src="~img/cultureimg/18.jpg" class="three_bg_img" data-1400="top:0rem;" data-2000="top:-2rem;"
            data-3000="top:-4rem;" />
          <img src="~img/indexImg/007.png" class="logo" />
          <div class="describe_title">
            <p v-html="text2.text"></p>
          </div> -->
        </div>
        <div class="describe_second" data-1400="top:-2rem;" data-2000="top:-4rem;" data-3000="top:-4rem;">
          <img src="~img/cultureimg/15.png" class="cpu" />
          <p v-html="text3.text"></p>
          <img src="~img/cultureimg/02.png" class="right_img" />
        </div>
        <!-- data-1400="top:-2rem;" data-2000="top:-3rem;" data-3500="top:-4rem;"
          data-4000="top:-4rem;" -->
        <div class="fenge_img" ref="fenge" data-2000="top:-1rem;" data-2500="top:-2rem;" data-3000="top:-2.5rem;">
          <img src="~img/cultureimg/30.png" class="bg_di" />
          <div class="describe_third">
            <p v-html="text4.text"></p>
          </div>
          <img src="~img/cultureimg/02.png" class="right" />
        </div>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <div class="web_top_img">
        <!-- <img src="~img/cultureimg/08.png" class="web_icon_img" /> -->
        <h2 ref="webItem-01">安谋科技（中国）有限公司</h2>
        <div ref="webItem-02" class="web_fenge"></div>
        <p ref="webItem-03" v-html="text1.text"></p>
      </div>
      <div class="web_one_box">
        <div class="web_content">
          <ul class="clearfix" ref="towBox">
            <li>
              <div class="num_text">
                <h2>{{ num }}</h2>
                <h3>+</h3>
                <h4>家</h4>
              </div>
              <p>中国客户数量</p>
            </li>
            <li>
              <div class="num_text">
                <h2>{{ num2 }}%</h2>
                <h3>+</h3>
              </div>
              <p>国产SoC基于Arm 处理器技术</p>
            </li>
            <li>
              <div class="num_text">
                <h2>{{ num3 }}</h2>
                <h3>+</h3>
                <h4>亿</h4>
              </div>
              <p>基于Arm架构"中国芯"累计出货量</p>
            </li>
            <li>
              <div class="num_text">
                <h2>{{ num6 }}</h2>
                <h3>%</h3>
                <h4>项</h4>
              </div>
              <p class="p4">自研产品线均已实现高质量交付<br />且助力客户成功流片</p>
            </li>
          </ul>
          <div ref="webItem-04" class="address clearfix">
            <div class="clearfix">
              <img src="~img/cultureimg/14.png" class="di_img" />
              <h3>办公地点</h3>
            </div>
            <p>深圳、上海、北京、成都</p>
            <img src="~img/cultureimg/17.png" class="fen_img" />
          </div>
        </div>
      </div>
      <div class="web_two_box">
        <div ref="webItem-05" class="personnel">
          <div class="box_se"></div>
          <div class="personnel_box" ref="people">
            <div class="box_left">
              <img src="~img/cultureimg/12.png" class="biao_img" />
              <p>在职员工</p>
              <div class="text">
                <h3>{{ num4 }}</h3>
                <h4>+</h4>
              </div>
            </div>
            <div class="box_right">
              <img src="~img/cultureimg/13.png" class="biao_img" />
              <p>自研业务核心技术专利数量</p>
              <div class="text">
                <h3>{{ num5 }}</h3>
                <h4>+</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="web_three_box" ref="describe" :style="{ 'background-position-y': positionY1 + 'px' }">
        <div ref="webItem-06">
          <img src="~img/indexImg/007.png" class="logo" />
          <div class="shuoming">
            <p v-html="text2.text">
            </p>
          </div>
        </div>
      </div> -->
      <div ref="webItem-07" class="web_four_box">
        <img src="~img/cultureimg/16.png" />
        <div class="box-t">
          <p v-html="text3.text"></p>
        </div>
      </div>
      <div class="web_five_box" ref="fenge">
        <div ref="webItem-08" class="web_six_box">
          <p v-html="text4.text"> </p>
          <img src="~img/cultureimg/02.png" />
        </div>
      </div>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import skrollr from 'skrollr'
import up from '@/components/Backtotop.vue'

import $ from 'jquery'

import 'animate.css'
import { debounce } from 'lodash'

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      skrollr: null,
      w: undefined,
      h: undefined, // 视口的高度
      only: true,
      equipment: undefined,
      showFooter: true,
      scrollTop: 0,
      positionY1: 0, //背景Y轴偏移量
      Y1: 0,
      positionY2: 0, //背景Y轴偏移量
      Y2: 0,
      ratio: 0.5, //视差偏移率
      clientType: 1,
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      randomNumber: true,
      peopleNums: true,
      setime: null,
      setime2: null,
      num: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      num5: 0,
      num6: 0,
      animateScroll: undefined,
      animateKeys: [],
      animateNames: {
        'webItem-01': 'fadeInDown',
        'webItem-03': 'fadeInUp',
      },
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    this.h = window.innerHeight // 视口的高度
    console.log(this.h)
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    this.getintroduce()
    document.title = '公司介绍 - 安谋科技'
  },
  mounted() {
    document.title = '公司介绍 - 安谋科技'
    window.addEventListener('scroll', this.handleScroll, true) //创建滚动监听，页面滚动回调handleScroll方法
    if (location.href.indexOf('#reloaded') == -1) {
      location.href = location.href + '#reloaded'
      location.reload()
    }

    // let bgTest = this.$refs.describe;
    let bgTest2 = this.$refs.fenge
    // this.Y1 = bgTest.offsetTop * this.ratio;
    this.Y2 = bgTest2.offsetTop * this.ratio
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        this.h = document.documentElement.clientHeight // 视口的高度
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
    if (this.equipment == 'pc') {
      this.skrollr = skrollr.init({
        smoothScrollingDuration: 700,
        mobileDeceleration: 0.05,
        edgeStrategy: 'set',
        forceHeight: false,
        easing: {
          WTF: Math.random,
          inverted: function(p) {
            return 1 - p
          },
        },
      })
    }

    this.animateInit()
  },
  watch: {
    scrollTop: function(value, oldvalue) {
      // if (this.equipment == "pc") {
      if (value > oldvalue) {
        // 下
        let documentop = this.$refs.towBox.getBoundingClientRect().top
        let people = this.$refs.people.getBoundingClientRect().top
        if (this.h > documentop) {
          this.Random(() => {
            clearInterval(this.setime)
          })
        }
        if (this.h - 200 > people) {
          this.peopleNum(() => {
            clearInterval(this.setime2)
          })
        }
      }
      // }
    },
  },

  methods: {
    // pc 滚轮事件
    handleScroll: function() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.positionY1 = this.Y1 - scrollTop * this.ratio //原始高度-滚动距离*视差系数
      this.positionY2 = this.Y2 - scrollTop * this.ratio
    },
    // 移动端滑动事件
    Pageslide() {},
    Random(close) {
      if (this.randomNumber) {
        this.randomNumber = false
        this.setime = setInterval(() => {
          if (this.num == 430) {
            this.num = 430
          } else {
            this.num += 10
          }
          if (this.num2 == 95) {
            this.num2 = 95
          } else {
            this.num2 += 5
          }
          if (this.num3 == 370) {
            this.num3 = 370
          } else {
            this.num3 += 10
          }
          if (this.num6 == 100) {
            this.num6 = 100
          } else {
            this.num6 += 5
          }
          if (this.num >= 430 && this.num2 >= 95 && this.num3 && this.num6) {
            close()
          }
        }, 40)
      }
    },

    peopleNum(close) {
      if (this.peopleNums) {
        this.peopleNums = false
        this.setime2 = setInterval(() => {
          if (this.num4 == 800) {
            this.num4 = 800
          } else {
            this.num4 += 20
          }
          if (this.num5 == 200) {
            this.num5 = 200
          } else {
            this.num5 += 5
          }
          if (this.num4 == 800 && this.num5 == 200) {
            close()
          }
        }, 30)
      }
    },
    async getintroduce() {
      const res = await this.axios.get(`/v1/api/introduce?clientType=1`)
      console.log(res)
      if (res.code == '00') {
        this.text1 = res.data[0].content
        this.text2 = res.data[1].content
        this.text3 = res.data[2].content
        this.text4 = res.data[3].content
      }
    },
    animateInit() {
      const { equipment, animateKeys, animateNames } = this
      const duration = 0.5 // 1.3
      // Object.keys(this.$refs)
      //   .filter((key) => key.startsWith(equipment) || key == "towBox")
      //   .forEach((key, index) => {
      //     const elem = this.$refs[key];
      //     const top = elem.getBoundingClientRect().top;

      //     // console.log('animate-init elem', elem, 'top', top);

      //     if (top < this.h) {
      //       Object.assign(elem.style, {
      //         opacity: 1,
      //         // 'visibility': 'visible',
      //         // "animation-name": animateNames[key] || `fadeIn`,
      //         // "animation-duration": `${duration}s`,
      //         // "animation-delay": `${duration * index * 1}s`,
      //         // "animation-fill-mode": "forwards",
      //         "animation-name": animateNames[key] || `fadeIn`,
      //         "animation-duration": `.1s`,
      //         // "animation-delay": `${duration * index * 1}s`,
      //         "animation-fill-mode": "forwards",
      //       });
      //     } else {
      //       Object.assign(elem.style, {
      //         opacity: 1,
      //         "animation-name": "none",
      //       });
      //       animateKeys.push(key);
      //     }
      //   });

      // 注册滚动事件
      this.animateScroll = debounce(this.animatePlay, 80)
      window.addEventListener('scroll', this.animateScroll, true)
    },
    animatePlay(e) {
      // console.log('animate-play', e)
      const duration = 0.6
      const animateKeys = this.animateKeys
        .map((key, index) => {
          const elem = this.$refs[key]
          const top = elem.getBoundingClientRect().top

          if (top - 30 < this.h) {
            Object.assign(elem.style, {
              opacity: 0,
              'animation-name': this.animateNames[key] || `fadeIn`,
              'animation-duration': `${duration}s`,
              'animation-delay': `${duration * index + 1 * 1}s`,
              'animation-fill-mode': 'forwards',
            })
          } else {
            Object.assign(elem.style, {
              opacity: 0,
              'animation-name': 'none',
            })
            return key
          }
        })
        .filter(Boolean)
      this.animateKeys.splice(0, this.animateKeys.length, ...animateKeys)
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
    window.removeEventListener('scroll', this.animateScroll, true)
  },
}
</script>

<style lang="less" scoped>
.brief {
  position: relative;
  background: #131217;
  overflow: hidden;
  .pc {
    transition: 0.5s all;
    .pc_one_box {
      height: 10.8rem /* 1080/100 */;
      overflow: hidden;
      position: relative;
      .pc_fenge {
        width: 242px;
        height: 5px;
        background: #00b3d6;
        position: absolute;
        top: 50%;
        left: 0.8rem /* 135/100 */;
        transform: translate(0, -50%);
        font-size: 0.8rem /* 80/100 */;
        color: #00b3d6;
      }
      .bg_img {
        width: 100%;
        position: absolute;
        top: 0;
      }
      .icon_img {
        width: 0.38rem /* 38/100 */;
        position: absolute;
        top: 2.21rem /* 221/100 */;
        right: 1.19rem /* 119/100 */;
      }
      h3 {
        position: absolute;
        top: 41%;
        left: 0.8rem /* 135/100 */;
        transform: translate(0, -50%);
        font-size: 0.8rem /* 80/100 */;
        color: #00b3d6;
      }
      p {
        position: absolute;
        top: 70%;
        right: 0.8rem /* 191/100 */;
        transform: translate(0, -50%);
        width: 7.34rem /* 601/100 */;
        font-size: 0.22rem /* 22/100 */;
        color: #c8c8c8;
        line-height: 0.38rem /* 38/100 */;
        text-shadow: 0px 7px 16px #000000;
      }
    }
    .pc_two_box {
      height: 8.82rem /* 882/100 */;
      background: #000;
      padding-left: 1.5rem /* 144/100 */;
      padding-right: 1.5rem /* 200/100 */;
      .content {
        height: 100%;
        width: 100%;
        // background-color: skyblue;
        padding-top: 2.15rem /* 215/100 */;
        position: relative;
        .tow-box {
          width: 100%;
          position: absolute;
        }
        .new_box {
          height: 6rem;
          width: 100%;
          position: absolute;
          // left: 0;
          .text_img {
            width: 7.07rem /* 707/100 */;
          }
        }
        ul {
          display: flex;
          li {
            width: 25%;
            height: 1.4rem /* 140/100 */;
            // background-color: #fff;
            // background-color: hotpink;
            position: relative;
            .text_box {
              .p1 {
                font-size: 1.16rem /* 116/100 */;
                color: #fff;
                font-weight: bold;
                line-height: 1.4rem /* 140/100 */;
                float: left;
              }
              .p2 {
                line-height: 0.25rem;
                font-size: 0.8rem /* 80/100 */;
                color: #fff;
                padding-top: 0.3rem /* 10/100 */;
              }
              .p3 {
                position: absolute;
                font-size: 0.4rem /* 45/100 */;
                color: #fff;
                bottom: 0.1rem /* 10/100 */;
                right: 1rem /* 122/100 */;
              }
            }

            h5 {
              font-size: 0.22rem /* 22/100 */;
              color: #00b3d6;
              margin-left: 0.05rem /* 5/100 */;
            }
          }
          li:nth-child(2) {
            padding-left: 0.5rem /* 20/100 */;
          }
          li:nth-child(3) {
            padding-left: 0.6rem /* 20/100 */;
          }
          li:nth-child(3) {
            padding-left: 0.7rem /* 20/100 */;
            .p3 {
              right: 0.3rem /* 10/100 */;
            }
          }
          li:nth-child(4) {
            padding-left: 0.7rem /* 20/100 */;
            .p3 {
              right: 0;
            }
          }
        }
        .address {
          position: absolute;
          bottom: -0.6rem /* 24/100 */;
          width: 100%;
          img {
            width: 0.55rem /* 45/100 */;
            float: left;
            margin-right: 0.2rem /* 31/100 */;
            padding-top: 0.07rem;
          }
          h4 {
            font-size: 0.72rem /* 72/100 */;
            line-height: 0.67rem /* 67/100 */;
            margin-bottom: 0.12rem /* 12/100 */;
            color: #fff;
          }
          p {
            font-size: 0.26rem /* 26/100 */;
            color: #989898;
            line-height: 0.38rem /* 38/100 */;
            margin-left: 0.74rem /* 74/100 */;
            // margin-top: 0.12rem /* 12/100 */;
          }
        }
        .bantouming {
          width: 8.47rem /* 847/100 */;
          height: 4.08rem /* 408/100 */;
          background-color: #00b3d6;
          position: absolute;
          right: 0 /* 200/100 */;
          bottom: -0.61rem /* 61/100 */;
          opacity: 0.5;
        }
        .pc_label {
          width: 8.47rem /* 847/100 */;
          height: 4.08rem /* 408/100 */;
          position: absolute;
          right: 0 /* 200/100 */;
          bottom: -0.61rem /* 61/100 */;
          padding-top: 0.72rem /* 72/100 */;
          p {
            font-size: 0.22rem /* 22/100 */;
            margin-top: 0.1rem /* 10/100 */;
            color: #fff;
            opacity: 0.5;
          }
          .span1 {
            font-size: 1.16rem /* 116/100 */;
            line-height: 1.4rem /* 140/100 */;
            color: #fff;
          }
          .span2 {
            font-size: 0.8rem /* 80/100 */;
            line-height: 0.7rem /* 100/100 */;
            float: right;
            color: #fff;
          }
          .left {
            width: 53.5%;
            float: left;
            padding-left: 0.79rem /* 79/100 */;
            img {
              opacity: 0.8;
              width: 0.9rem /* 90/100 */;
            }
            .span2 {
              margin-right: 1.1rem /* 100/100 */;
            }
          }
          .right {
            img {
              opacity: 0.8;
              width: 0.9rem /* 75/100 */;
            }
            .span2 {
              margin-right: 1.3rem /* 100/100 */;
            }
          }
        }
      }
    }
    .pc_three_box {
      position: relative;
      // overflow: hidden;
      .title_img {
        width: 7.07rem /* 707/100 */;
        position: absolute;
        top: 0;
        left: 1.34rem /* 134/100 */;
      }
      .jiange {
        height: 2.85rem /* 285/100 */;
      }
      .describe {
        // height: 7.08rem /* 708/100 */;
        height: 2rem /* 708/100 */;

        position: relative;
        overflow: hidden;
        .three_bg_img {
          // z-index: -1;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .logo {
          width: 2.19rem /* 219/100 */;
          position: absolute;
          left: 50%;
          top: 1.07rem;
          transform: translate(-50%);
        }
        .describe_title {
          position: absolute;
          color: #fff;
          left: 50%;
          top: 4.07rem;
          transform: translate(-50%);
          p {
            text-align: center;
            font-size: 0.22rem /* 22/100 */;
            font-weight: 300;
          }
        }
      }
      .describe_second {
        height: 6.5rem /* 989/100 */;
        position: relative;
        .cpu {
          width: 8.65rem /* 865/100 */;
          position: absolute;
          top: 1rem /* 181/100 */;
          left: 2.75rem /* 275/100 */;
        }
        .right_img {
          width: 1.17rem /* 117/100 */;
          position: absolute;
          right: 2.5rem /* 250/100 */;
          top: 2.36rem /* 316/100 */;
        }
        p {
          position: absolute;
          top: 3.76rem /* 456/100 */;
          left: 9.3rem /* 930/100 */;
          color: #fff;
          font-weight: 300;
          font-size: 0.22rem /* 22/100 */;
          width: 7.14rem /* 714/100 */;
        }
      }
      .fenge_img {
        height: 6.95rem /* 695/100 */;
        position: relative;
        // overflow: hidden;
        .bg_di {
          width: 100%;
          position: absolute;
          top: -50%;
        }
        .describe_third {
          position: absolute;
          left: 50%;
          top: 2.15rem /* 215/100 */;
          transform: translate(-50%);
          font-size: 0.34rem /* 34/100 */;
          font-weight: 300;
          color: #fff;
        }
        .right {
          width: 1.17rem /* 117/100 */;
          position: absolute;
          right: 2.5rem /* 250/100 */;
          bottom: 1.33rem /* 133/100 */;
        }
      }
    }
  }

  .web {
    & .web_nav {
      visibility: visible;
      animation: fadeIn 2.5s;
      animation-fill-mode: forwards;
    }

    .web_top_img {
      height: 519px;
      padding-top: 199.5px;
      position: relative;
      background: url('~img/cultureimg/11.png') no-repeat;
      background-size: contain;
      .web_icon_img {
        width: 163.5px;
        position: absolute;
        top: 87px;
        right: 25px;
      }
      h2 {
        color: #0db2d6;
        font-size: 27px;
        font-weight: bold;
        margin-left: 26px;
      }
      .web_fenge {
        width: 121px;
        height: 2.5px;
        background: #0db2d6;
        margin-top: 20.5px;
        margin-left: 26px;
      }
      p {
        color: #c8c8c8;
        font-size: 12px;
        line-height: 21px;
        margin-top: 47px;
        margin-left: 25.5px;
        margin-right: 23px;
      }
    }
    .web_one_box {
      height: 526px;
      background: #131217;
      padding-top: 92.5px;
      padding-left: 26.5px;
      .web_content {
        width: 100%;
        height: 100%;
        ul {
          li {
            width: 50%;
            float: left;
            margin-bottom: 50.5px;
            .num_text {
              height: 42px;
              position: relative;
              h2 {
                font-size: 53px;
                line-height: 42px;
                float: left;
                color: #fff;
              }
              h3 {
                font-size: 30px;
                color: #fff;
                float: left;
                line-height: 15px;
              }
              h4 {
                line-height: 60px;
                font-size: 20px;
                color: #fff;
              }
            }
            p {
              width: 100.5px;
              color: #0db2d6;
              margin-top: 18.5px;
            }
            .p4 {
              width: 100%;
            }
          }
        }
        .address {
          margin-top: 10px;
          .di_img {
            margin-top: 5px;
            width: 22.5px;
            float: left;
          }
          h3 {
            float: left;
            font-size: 36px;
            line-height: 33.5px;
            color: #fff;
            margin-left: 15.5px;
          }
          p {
            margin-left: 37px;
            font-size: 13px;
            color: #989898;
            margin-top: 6px;
          }
          .fen_img {
            width: 86%;
            margin-top: 15px;
          }
        }
      }
    }
    .web_two_box {
      .personnel {
        height: 269.5px;
        position: relative;
        .box_se {
          width: 100%;
          height: 180.5px;
          position: absolute;
          top: 0;
          background: #0db2d6;
          opacity: 0.5;
        }
        .personnel_box {
          height: 180.5px;
          position: relative;
          .box_left {
            width: 50%;
            height: 100%;
            float: left;
            padding-left: 35px;
            padding-top: 32px;
            img {
              width: 40px;
            }
            p {
              color: #d4d4d4;
              font-size: 10px;
              margin-top: 6.5px;
            }
          }
          .box_right {
            width: 50%;
            height: 100%;
            float: right;
            padding-top: 32px;
            padding-left: 25px;
            img {
              width: 43px;
            }
            p {
              color: #d4d4d4;
              font-size: 10px;
              margin-top: 4.5px;
            }
          }
          .text {
            h3 {
              float: left;
              font-size: 51px;
              color: #ffffff;
            }
            h4 {
              font-size: 35px;
              color: #ffffff;
            }
          }
        }
      }
    }
    .web_three_box {
      width: 100%;
      height: 504.5px;
      position: relative;
      background: url('~img/cultureimg/20.png') no-repeat;
      background-size: 100%;
      // background-attachment: fixed;
      .logo {
        width: 109.5px;
        position: absolute;
        left: 50%;
        top: 87px;
        transform: translate(-50%);
      }
      .shuoming {
        width: 86.87%;
        color: #fff;
        position: absolute;
        top: 269.5px;
        left: 50%;
        transform: translate(-50%);
        p {
          font-size: 12px;
          // text-align: center
          line-height: 22px;
          font-weight: 300;
          text-align: center;
        }
      }
    }
    .web_four_box {
      // height: 526px;
      position: relative;
      background: #1b1a20;
      .box-t {
        // height: 252px;
        position: relative;
        padding: 20px 0;
        // margin-top: 10px;
        p {
          font-size: 11.5px;
          font-weight: 300;
          line-height: 22px;
          color: #fff;
          width: 87.87%;
          margin: 10px auto;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
        }
      }
    }
    .web_five_box {
      width: 100%;
      height: 419.5px;
      position: relative;
      background: url('~img/cultureimg/21.png') no-repeat;
      background-size: 100%;
      // background-attachment: fixed;
    }
    .web_six_box {
      // background: #1b1a20;
      height: 419.5px;
      position: relative;
      p {
        width: 86%;
        font-size: 16.5px;
        font-weight: 300;
        color: #fff;
        line-height: 30px;
        position: absolute;
        top: 123px;
        left: 50%;
        transform: translate(-50%);
        span {
          color: #0db2d6;
        }
      }
      img {
        width: 59.5px;
        position: absolute;
        bottom: 100px;
        right: 25px;
      }
    }
    .foot {
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>
